import {
  Box,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Image,
  Heading,
  SimpleGrid,
  Input,
  VStack
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import { fetchData } from "../services/excelData";

const Books = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [certificates, setCertificates] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await fetchData(); 
        console.log("Fetched data:", data);
        const formattedCertificates = data.map((cert) => ({
          name: cert.NAME,
          img: (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="achievement" width="96"  >
  <path fill="#84dbff" d="M62 48.84H2c-1.1 0-2-.9-2-2V6.16c0-1.1.9-2 2-2h60c1.1 0 2 .9 2 2v40.68a2 2 0 0 1-2 2z"></path>
  <path fill="#f2f2f2" d="M58.42 15.56v21.89c-3.21 0-5.82 2.6-5.82 5.81H11.4c0-3.21-2.6-5.81-5.82-5.81V15.56c3.21 0 5.82-2.6 5.82-5.81h41.2c0 3.21 2.6 5.81 5.82 5.81z"></path>
  <path fill="#ff7157" d="M23.89 14.63h16.54v5.02H23.89z"></path>
  <path fill="#4bdcc5" d="M14.03 23.54h35.95v3.13H14.03zM14.03 29.39h35.95v3.13H14.03zM14.03 35.25h35.95v3.13H14.03z"></path>
  <path fill="#ff7058" d="m53.06 49.09 4.59 7.94-2.06-.37-.59-.11-.96-.18-.33.92-.21.58-.7 1.97-4.59-7.95z"></path>
  <path fill="#e8533f" d="m51.68 49.89 3.91 6.77-1.55-.29-.54 1.5-3.91-6.78z"></path>
  <path fill="#ff7058" d="m43.12 49.09-4.59 7.94 2.06-.37.59-.11.96-.18.33.92.21.58.7 1.97 4.59-7.95z"></path>
  <path fill="#e8533f" d="m44.5 49.89-3.91 6.77 1.55-.29.54 1.5 3.91-6.78z"></path>
  <path fill="#f4c95a" d="M58.11 43.84c0 1.25-1.78 2.14-2.23 3.23-.47 1.13.14 3.01-.71 3.86-.85.85-2.73.24-3.86.71-1.09.45-1.98 2.23-3.23 2.23s-2.14-1.78-3.23-2.23c-1.13-.47-3.01.14-3.86-.71-.85-.85-.24-2.73-.71-3.86-.45-1.09-2.23-1.98-2.23-3.23s1.78-2.14 2.23-3.23c.47-1.13-.14-3.01.71-3.86.85-.85 2.73-.24 3.86-.71 1.09-.45 1.98-2.23 3.23-2.23s2.14 1.78 3.23 2.23c1.13.47 3.01-.14 3.86.71.85.85.24 2.73.71 3.86.45 1.09 2.23 1.98 2.23 3.23z"></path>
  <path fill="#e2a144" d="M55.78 43.84c0 .96-1.36 1.64-1.71 2.48-.36.87.11 2.31-.54 2.96-.65.65-2.09.18-2.96.54-.83.35-1.52 1.71-2.48 1.71s-1.64-1.36-2.48-1.71c-.87-.36-2.31.11-2.96-.54-.65-.65-.18-2.09-.54-2.96-.35-.83-1.71-1.52-1.71-2.48s1.36-1.64 1.71-2.48c.36-.86-.11-2.31.54-2.96.65-.65 2.09-.18 2.96-.54.83-.35 1.52-1.71 2.48-1.71s1.64 1.36 2.48 1.71c.86.36 2.31-.11 2.96.54.65.65.18 2.09.54 2.96.34.83 1.71 1.52 1.71 2.48z"></path>
  <path fill="#edaf4b" d="M55.17 43.84c0 .88-1.26 1.51-1.57 2.28-.33.8.1 2.13-.5 2.73-.6.6-1.93.17-2.73.5-.77.32-1.4 1.57-2.28 1.57-.88 0-1.51-1.26-2.28-1.57-.8-.33-2.13.1-2.73-.5-.6-.6-.17-1.93-.5-2.73-.32-.77-1.57-1.4-1.57-2.28 0-.88 1.26-1.51 1.57-2.28.33-.8-.1-2.13.5-2.73.6-.6 1.93-.17 2.73-.5.77-.32 1.4-1.57 2.28-1.57.88 0 1.51 1.26 2.28 1.57.8.33 2.13-.1 2.73.5.6.6.17 1.93.5 2.73.32.76 1.57 1.39 1.57 2.28z"></path>
  <path fill="#fff" d="m46.47 47.09-2.46-2.45.83-.83 1.63 1.64 4.87-4.87.83.82z"></path>
</svg>
        ), 
          modalProps: {
            image: "https://marketplace.canva.com/EAFy42rCTA0/1/0/1600w/canva-blue-minimalist-certificate-of-achievement-_asVJz8YgJE.jpg"
          }
        }));
        setCertificates(formattedCertificates);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    loadData();
  }, []);

  const filteredCertificates = certificates.filter((cert) =>
    cert.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Layout>
      <Box pt={20}>
        <Center>
          <Heading fontFamily={"merriweather"} pb={20}>
            Certificates
          </Heading>
        </Center>

        {/* Search Bar */}
        <Center mb={10}>
          <Input
            placeholder="Search by name"
            width="300px"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Center>

        <SimpleGrid
          columns={{ base: "1", md: "4" }}
          spacingX="40px"
          spacingY="50px"
          mx={10}
          p={10}
        >
          {filteredCertificates.map((cert, index) => (
            <Center key={index}>
              <VStack>
                <Cards img={cert.img} />
                <h1>{cert.name}</h1>
                <Center mt={4}>
                  <Button
                    fontSize={"sm"}
                    rounded={"full"}
                    bg={"blue.400"}
                    color={"white"}
                    boxShadow={
                      "0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)"
                    }
                    _hover={{
                      bg: "blue.500",
                    }}
                    _focus={{
                      bg: "blue.500",
                    }}
                  >
                    <Basicmodal
                      text="OPEN"
                      name={cert.modalProps.name}
                      author={cert.modalProps.author}
                      bookdetails={cert.modalProps.bookdetails}
                      image={cert.modalProps.image}
                    />
                  </Button>
                </Center>
              </VStack>
            </Center>
          ))}
        </SimpleGrid>
      </Box>
    </Layout>
  );
};

const Cards = ({ img }) => {
  return (
    <Box
      maxW={{ base: "200px", sm: "250px", md: "300px" }}
      boxShadow={"2xl"}
      rounded={"lg"}
      pos={"relative"}
      zIndex={1}
      _hover={{
        cursor: "pointer",
        transition: "all .3s ease",
        transform: "scale(1.03)",
      }}
    >
      <Center>
        <Box width="100%" height="100%">
          {img}  {/* Here, img should be a JSX element (not HTML string) */}
        </Box>
      </Center>
    </Box>
  );
};


function Basicmodal({ text, name, author, bookdetails, image }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Center onClick={onOpen}>{text}</Center>

      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        size={"6xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Center
              display={"flex"}
              flexDirection={{ base: "column", md: "row" }}
            >
              <Image src={image} rounded={"lg"} />
            </Center>
          </ModalBody>
          <ModalFooter>
            <Button
              fontSize={"sm"}
              rounded={"full"}
              bg={"blue.400"}
              color={"white"}
              boxShadow={
                "0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)"
              }
              _hover={{
                bg: "blue.500",
              }}
              _focus={{
                bg: "blue.500",
              }}
              mr={3}
              onClick={onClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Books;
