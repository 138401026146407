
    import Papa from "papaparse";

    export const fetchData = async () => {
    const response = await fetch(
        "https://docs.google.com/spreadsheets/d/e/2PACX-1vS9dyeBEXWHYQL0Eng9NtyMWitTiQ8MaexD30N4Bg9PqVTVNKu6XfHG4SF92e6xhVvwYTfpsa-Y3QIk/pub?output=csv"
    );
    const csvData = await response.text();

    return new Promise((resolve, reject) => {
        Papa.parse(csvData, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
            resolve(results.data);
        },
        error: (err) => {
            reject(err);
        },
        });
    });
    };
